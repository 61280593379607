import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const RsvpSuccessAttending = () => {
    return (
        <Layout>
            <Helmet>
                <title>Thanks for responding!</title>
            </Helmet>
            <div id="main">
                <h1>Thanks for your response!</h1>
                <p>Thanks for confirming that you can come! You should receive an email within the next hour or two confirming your submission</p>
                <p><a href={"/"}>Home</a></p>
            </div>
        </Layout>
    )
}

export default RsvpSuccessAttending;